// import imagesLoaded from "imagesloaded";
export default class Util {
  static element(selector) {
    return document.querySelector(selector);
  }

  static elements(selectors) {
    return document.querySelectorAll(selectors);
  }

  static create(elem) {
    return document.createElement(elem);
  }

  static debounce(func, delay) {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  static preloadImages = (selector = 'img') => {
    return new Promise((resolve) => {
      imagesLoaded(
        document.querySelectorAll(selector),
        { background: true },
        resolve,
      );
    });
  };

  static onMouseMove(event, mouse) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }
  // Helper function that lets you dynamically figure out a grid's rows/columns as well as further refine those with "odd" or "even" ones
  // https://greensock.com/forums/topic/34808-how-can-i-animate-the-odd-and-even-columns-rows-of-a-grid-with-gsapto/?do=findComment&comment=174346
  static getGrid = (selector) => {
    let elements = gsap.utils.toArray(selector),
      bounds,
      getSubset = (axis, dimension, alternating, merge) => {
        let a = [],
          subsets = {},
          onlyEven = alternating === 'even',
          p;
        bounds.forEach((b, i) => {
          let position = Math.round(b[axis] + b[dimension] / 2),
            subset = subsets[position];
          subset || (subsets[position] = subset = []);
          subset.push(elements[i]);
        });
        for (p in subsets) {
          a.push(subsets[p]);
        }
        if (onlyEven || alternating === 'odd') {
          a = a.filter((el, i) => !(i % 2) === onlyEven);
        }
        if (merge) {
          let a2 = [];
          a.forEach((subset) => a2.push(...subset));
          return a2;
        }
        return a;
      };
    elements.refresh = () =>
      (bounds = elements.map((el) => el.getBoundingClientRect()));
    elements.columns = (alternating, merge) =>
      getSubset('left', 'width', alternating, merge);
    elements.rows = (alternating, merge) =>
      getSubset('top', 'height', alternating, merge);
    elements.refresh();

    return elements;
  };

  static getMousePos = (e) => {
    return {
      x: e.clientX,
      y: e.clientY,
    };
  };

  // Returns the window width and height
  static getWinSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  static isFirefox = () =>
    navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  static wrapElements = (elems, wrapType, wrapClass) => {
    elems.forEach((char) => {
      const wrapEl = document.createElement(wrapType);
      wrapEl.classList = wrapClass;
      char.parentNode.appendChild(wrapEl);
      wrapEl.appendChild(char);
    });
  };

  static modalInit(elements, modal, closeButton) {
    if (elements.length > 0) {
      elements.forEach(function (element) {
        element.addEventListener('click', function (e) {
          e.preventDefault();
          Util.showModal(modal);
        });
      });
      let containerModal = modal.querySelector('.modal--container');
      modal.onclick = function (event) {
        if (event.target != containerModal) {
          Util.hideModal(modal);
        }
      };
    } else {
      elements.addEventListener('click', function (e) {
        e.preventDefault();
        Util.showModal(modal);
        if (closeButton) {
          closeButton.addEventListener('click', function (e) {
            Util.hideModal(modal);
          });
        }
      });

      let containerModal = modal.querySelector('.modal--container');
      modal.onclick = function (event) {
        if (event.target != containerModal) {
          Util.hideModal(containerModal);
        }
      };
    }
  }

  static showModal(modal) {
    modal.classList.add('is-open');
    document.body.style.overflow = 'hidden';
  }

  static hideModal(modal) {
    modal.classList.remove('is-open');
    document.body.style.overflow = 'initial';
  }
}
