import '../style/index.scss';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import Util from './util/util';
import gsap from 'gsap';

document.addEventListener('DOMContentLoaded', function () {
  const videoLoader = document.getElementById('video-loader');
  const loaderVideo = document.getElementById('loader-video');
  const mainVideo = document.querySelector('.video--container video');
  const header = document.querySelector('header');

  if (videoLoader) {
    let timerStarted = false;
    const timerStartTime = Date.now(); // Start the timer immediately on page load

    loaderVideo.addEventListener('canplaythrough', () => {
      document.body.classList.add('loaded');
    });

    mainVideo.addEventListener('canplay', () => {
      if (!timerStarted) {
        const elapsed = Date.now() - timerStartTime;
        const limiter = window.innerWidth > 768 ? 5000 : 2000; // Minimum display time based on device
        const remainingTime = Math.max(0, limiter - elapsed);

        header.style.display = 'flex';

        setTimeout(() => {
          console.log('video main');
          mainVideo.play();
          videoLoader.classList.add('loaded');
        }, remainingTime);

        timerStarted = true;
      }
    });

    setTimeout(() => {
      header.style.display = 'flex';
      mainVideo.play();
      videoLoader.classList.add('loaded');
    }, 5000);
  }

  // const $smallBall = document.querySelector('.cursor__ball--small');

  // if (window.innerWidth > 768) {
  //   document.addEventListener('mousemove', onMouseMove);
  //   function onMouseMove(e) {
  //     $smallBall.style.opacity = '1';
  //     gsap.to($smallBall, {
  //       x: e.clientX + 4,
  //       y: e.clientY + 4,
  //       duration: 0.3,
  //     });
  //   }
  // }
  // <div class="cursor">
  //   <div class="cursor__ball cursor__ball--small">
  //     <img
  //       src="<?php echo get_template_directory_uri() . '/assets-preview/img/limone-g-c.svg' ?>"
  //       alt=""
  //     />
  //   </div>
  // </div>;
  // const grid = document.querySelector('.spazi__grid');

  // // Shuffle grid items

  // if (grid) {
  //   function getGutterSize() {
  //     if (window.innerWidth < 768) return 10; // Smaller gutter for mobile
  //     if (window.innerWidth < 1024) return 20; // Medium screens
  //     return 25; // Default gutter for large screens
  //   }

  //   let msnry = new Masonry(grid, {
  //     itemSelector: '.grid--item',
  //     columnWidth: '.grid-sizer',
  //     percentPosition: true,
  //     gutter: getGutterSize(),
  //   });

  //   imagesLoaded(grid).on('progress', function (instance, image) {
  //     msnry.layout();
  //     image.img.style.opacity = 1;
  //   });
  // }

  const menu = document.querySelector('header');

  window.addEventListener('scroll', () => {
    if (window.scrollY > 20) {
      menu.classList.add('scrolled');
    } else {
      menu.classList.remove('scrolled');
    }
  });

  const menuButton = document.querySelector('.menu--button');
  const menuMobile = document.querySelector('.menu--mobile');
  const menuButtonClose = menuMobile.querySelector('.close');
  if (menuMobile && window.innerWidth < 768) {
    menuButton.addEventListener('click', () => {
      menuMobile.classList.toggle('open');
    });
    menuButtonClose.addEventListener('click', () => {
      menuMobile.classList.toggle('open');
    });
    menuMobile.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', () => {
        menuMobile.classList.toggle('open');
      });
    });
  }

  const mapEl = document.querySelector('.map');

  if (mapEl) {
    const mapOptions = {
      center: { lat: 40.59975300113893, lng: 14.346682007321943 }, // Your location
      zoom: 13,
      disableDefaultUI: true,
      styles: [
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [
            {
              hue: '#7fc8ed',
            },
            {
              saturation: 55,
            },
            {
              lightness: -6,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels',
          stylers: [
            {
              hue: '#7fc8ed',
            },
            {
              saturation: 55,
            },
            {
              lightness: -6,
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#83cead',
            },
            {
              saturation: 1,
            },
            {
              lightness: -15,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#f3f4f4',
            },
            {
              saturation: -84,
            },
            {
              lightness: 59,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'labels',
          stylers: [
            {
              hue: '#ffffff',
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#ffffff',
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              hue: '#bbbbbb',
            },
            {
              saturation: -100,
            },
            {
              lightness: 26,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#ffcc00',
            },
            {
              saturation: 100,
            },
            {
              lightness: -35,
            },
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              hue: '#ffcc00',
            },
            {
              saturation: 100,
            },
            {
              lightness: -22,
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi.school',
          elementType: 'all',
          stylers: [
            {
              hue: '#d7e4e4',
            },
            {
              saturation: -60,
            },
            {
              lightness: 23,
            },
            {
              visibility: 'on',
            },
          ],
        },
      ],
    };

    const map = new google.maps.Map(mapEl, mapOptions);

    // Custom Marker Icon
    const markerIcon = {
      url: 'https://lubracasarelax.com/wp-content/themes/temp/assets-t/img/map-pin-fill.png',
      scaledSize: new google.maps.Size(32, 32), // Resize the marker
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 32), // Center bottom anchor
    };

    // Add Marker
    new google.maps.Marker({
      position: { lat: 40.59975300113893, lng: 14.346682007321943 }, // Same as center
      map: map,
      icon: markerIcon,
      title: 'Lubra Casa relax', // Optional tooltip
    });
  }

  const contactForm = document.getElementById('contactForm');
  const submitButton = contactForm.querySelector('button[type="submit"]');
  initBookNow();
  if (contactForm) {
    contactForm.addEventListener('submit', async (e) => {
      e.preventDefault();

      submitButton.disabled = true;
      submitButton.textContent = 'Inviando...';

      const formData = new FormData(contactForm);

      try {
        const response = await fetch(
          'https://lubracasarelax.com/wp-content/themes/temp/send-email.php',
          {
            method: 'POST',
            body: formData,
          },
        );

        const result = await response.json();

        if (result.success) {
          submitButton.textContent = 'Email Inviata';
          submitButton.disabled = true;
          contactForm.reset();
        } else {
          alert('Something went wrong. Please try again later.');
        }
      } catch (error) {
        alert('An error occurred. Please try again later.');
      }
    });
  }

  function initBookNow() {
    const modal = document.querySelector('.modal--form');
    const modalButton = document.querySelector('.book--now');
    const closeButton = document.querySelector('.modal__close');
    if (modal && modalButton) {
      Util.modalInit(modalButton, modal, closeButton);
    }
  }

  // const elements = document.querySelectorAll('.gallery--slider');
  // elements.forEach((el) => {
  //   let flickity = new Flickity(el, {
  //     cellSelector: '.room--image',
  //     wrapAround: true,
  //     prevNextButtons: false,
  //     pageDots: false,
  //     imagesLoaded: true,
  //     selectedAttraction: 0.05,
  //     friction: 0.8,
  //   });
  //   setTimeout(() => {
  //     flickity.resize();
  //   }, 100);
  // });
});
